/* Unique color gradients for each button */
/*
.fb-button {
  background: linear-gradient(135deg, rgba(59, 89, 152, 0.8), rgba(76, 111, 183, 0.8)); 
}

.ig-button {
  background: linear-gradient(135deg, rgba(225, 48, 108, 0.8), rgba(245, 96, 64, 0.8)); 
}

.gmail-button {
  background: linear-gradient(135deg, rgba(217, 48, 37, 0.8), rgba(234, 67, 53, 0.8)); 
}
.wa-button {
  background: linear-gradient(135deg, rgba(37, 211, 102, 0.8), rgba(40, 199, 97, 0.8)); 
}
*/

/* Floating Bubble Effect */
.social-button {
  position: relative;
  width: 80px; /* Adjusted for uniform bubble size */
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-button::before {
  content: '';
  position: absolute;
  width: 120%; /* Increased slightly to extend bubble background */
  height: 120%;
  background: rgba(216, 179, 174, 0.5); /* Match transparency with Google Maps shadow */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  animation: float-bubble 6s ease-in-out infinite;
}

.social-button:hover {
  transform: scale(1.1); /* Enlarges bubble on hover */
}

/* Social button icon */
.icon {
  width: 35px; /* Adjust size of icons */
  height: 35px;
}

/* Button text styling */
.button-text {
  margin-top: 5px;
  font-size: 0.75rem;
  color: #fff;
}

/* Layout of social buttons in a row */
.social-buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px; /* Adjust spacing between bubbles */
  padding: 20px;
}

/* Bubble animation */
@keyframes float-bubble {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -55%) scale(1.1);
  }
}

/* Button floating animation */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.google-map-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 20px;
  height: 600px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  animation: borderPulse 3s infinite; /* Add animation */
}

/* Keyframes for the pulsing border effect */
@keyframes borderPulse {
  0%, 100% {
    box-shadow: 0 0 15px rgba(216, 179, 174, 0.5), 
                0 0 25px rgba(234, 230, 227, 0.4), 
                0 0 35px rgba(240, 240, 240, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(239, 234, 234, 0.6), 
                0 0 30px rgba(236, 233, 232, 0.5), 
                0 0 40px rgba(243, 242, 242, 0.4);
  }
}

@media (max-width: 835px) and (max-height: 1200px) {
  .google-map-container {
    height: 50vh;
  }
}

@media (max-width: 885px) and (max-height: 1105px) {
  .google-map-container {
    height: 37vh;
  }
}

@media (max-width: 801px) and (max-height: 1280px) {
  .google-map-container {
    height: 33vh;
  }
}

@media (max-width: 380px) and (max-height: 815px) {
  .google-map-container {
    height: 30vh;
  }
}

@media (max-width: 820) and (max-height: 1180) {
  .google-map-container {
    height: 20vhh;
  }
}

@media (max-width: 1000px) and (max-height: 1000px) {
  .google-map-container {
    height: 20vh;
  }
}

@media (max-width: 1200px) and (max-height: 800px) {
  .google-map-container {
    height: 27vh;
  }
}

@media (max-width: 1280px) and (max-height: 800px) {
  .google-map-container {
    height: 54vh;
  }
}

@media (max-width: 375px) and (max-height: 813px) {
  .google-map-container {
    height: 64vh;
  }
}
