.news-feed-container {
    max-width: 600px;
    margin: 2rem auto;
    font-family: Arial, sans-serif;
    height: 400px; /* Adjust the height as needed */
    overflow-y: auto; /* Allows vertical scrolling */
    position: relative;
  }
  
  .scroll-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 2rem;
    cursor: pointer;
  }

  
  /* Hide scrollbar */
  .news-feed-container::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  
  /* Hide scrollbar for Firefox */
  .news-feed-container {
    scrollbar-width: none; /* Firefox */
  }
  
  /* Style for the News Feed Title */
  .news-feed-title {
    text-align: center;
    font-size: 2rem;
    color: #020202;
    margin-bottom: 1rem;
  }
  
  .news-feed-form {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #0b0b0b;
    border-radius: 8px;
  }
  
  .news-feed-form input,
  .news-feed-form textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .news-feed-form button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .news-feed-form button:hover {
    background-color: #0056b3;
  }
  
  /* Style for News Item */
  .news-item {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
  }
  
  .news-item-title {
    font-size: 1.5rem;
    color: #1e1d1d;
    margin: 0 0 0.5rem;
  }
  
  .news-item-content {
    font-size: 1rem;
    color: #fffafa;
    margin-bottom: 0.5rem;
  }
  
  .news-item-date {
    font-size: 0.875rem;
    color: #000000;
  }
  
  /* Scroll arrow always visible on the right */
  .scroll-arrow {
    position: fixed;
    right: 15px; /* Position on the right side */
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
    font-size: 2rem;
    color: #fdfefe;
    animation: pulse 1.5s infinite; /* Pulsing effect */
    cursor: pointer;
    z-index: 10; /* Ensure it is above the feed content */
  }
  
  /* Pulsing animation */
  @keyframes pulse {
    0% {
      transform: translateY(-50%) scale(1);
    }
    50% {
      transform: translateY(-50%) scale(1.2);
    }
    100% {
      transform: translateY(-50%) scale(1);
    }
  }
  