/* Description Styles */
.description {
	text-align: center;
	padding: 15px;	
}

.description h1 {
	font-size: 28px;
	margin-bottom: 10px;
}

.description p,h1 {
	font-size: 16px;
	color: #000000;
}
