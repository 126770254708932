
.menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 30px;
}

.dropdown-menu {
	position: absolute;
	background: linear-gradient(45deg, hsl(0, 50%, 50%), #e64646, #7f0101);
    background: -webkit-linear-gradient(to left, #e64646 , #7f0101);
    margin-top: 120px;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000; /* Ensure it appears above other content */
    animation-name: drop;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
}

@keyframes drop {
    0% {
    transform: translateY(-100%);
    }
    100% {
    transform: translateY(50);
    }
}


.dropdown-menu ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 50px;
}

.dropdown-menu ul li {
	font-size: xx-large;
	padding: 20px;
	cursor: pointer;
	text-align: center;
}
.dropdown-menu a {
	color: #000000;
	text-decoration: none;
}

.dropdown-menu ul li:hover {
	background-color: #ea5a5a;
}

@media(max-width: 500px) {

	.dropdown-menu { 
		margin-top:70px
	}
}

@media (max-width: 375px) {

	.dropdown-menu { 
		margin-top:70px
	}
}

@media (max-width: 320px) {

	.dropdown-menu { 
		margin-top:70px
	}
}


@media (min-width: 500px) and (max-width: 1000px) {
	.dropdown-menu { 
		margin-top:60px
	}
}