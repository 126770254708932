.footer {
    background: black;
    color: white;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
}

.footer p {
    margin-top: 10px
}


@media (max-width: 375px) {
    .footer { 
        height: 30px;
    }
    .footer p {
        font-size:  x-small;
    }
}

@media (max-width: 320px) {
    .footer p {
        font-size:  xx-small;
    }
}
