/* Nav bar Styles */
.navbar {
	background: linear-gradient(45deg, hsl(0, 0%, 0%), #000000, #000000);
}

.navbar-container {
	display: flex;
	align-items: center;
}

.logo-container {
	padding:10px;
	display: flex;
	align-items: center;
}

.logo {
	width: 100px;
}

.navbar-links {
	margin-right: 10px;
	list-style-type: none;
	display: flex;
}

.navbar-links li {
	margin-right: 20px;
}

.navbar-links li:last-child {
	margin-right: 0;
}

.navbar-links li a {
	text-decoration: none;
	color: white;
	font-weight: bold;
	font-size: 16px;
}

.culture-flags img{
	height: 35px;
	cursor: pointer;
}

.culture-flags { 
	margin-right:10px;
	position: absolute;
	right: 0;
}

.culture-flags img{ 
	margin-right:5px;
}


  

@media(max-width: 500px) {
	.logo {
		width: 50px;
	}
	.navbar-links li a {
		font-size: 15px;
	}
	.navbar-links li {
		margin-right: 10px;
	}
	.dropdown-menu { 
		margin-top:70px
	}
}

@media (max-width: 375px) {
	.logo {
		width: 50px;
	}
    .navbar-links li a {
		font-size: 14px;
	}
	.navbar-links li {
		margin-right: 10px;
	}
	.culture-flags img{
		height: 30px;
		cursor: pointer;
	}
	.dropdown-menu { 
		margin-top:70px
	}
}

@media (max-width: 320px) {
	.logo {
		width: 50px;
	}
    .navbar-links li a {
		font-size: 10px;
	}
	.navbar-links li {
		margin-right: 10px;
	}
	.culture-flags img{
		height: 20px;
		cursor: pointer;
	}
	.dropdown-menu { 
		margin-top:70px
	}
}


@media (min-width: 500px) and (max-width: 1000px) {

	.logo {
		width: 40px;
	}
    .navbar-links li a {
		font-size: 5px;
	}
	.navbar-links li {
		margin-right: 5px;
	}
	.culture-flags img{
		height: 20px;
		cursor: pointer;
	}
	.dropdown-menu { 
		margin-top:70px
	}
}