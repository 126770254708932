/* Styling for the header with coach name and image */
.course-header {
    display: flex;
    align-items: center; /* Vertically centers items */
    justify-content: space-between; /* Space between coach name and image */
    margin-bottom: 15px; /* Space between header and course content */
}

/* Coach name styling */
.coach-name {
    margin: 0;
    color: #000000;
    font-weight: bold;
    font-size: 1.5em;
    flex: 1; /* Allows name to grow if necessary */
    text-align: left;
}

/* Course image styling */
.course-image {
    width: 150px; /* Fixed width for the image on the right */
    height: 150px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    margin-left: 10px; /* Space between image and coach name */
    flex-shrink: 0; /* Prevents image from shrinking */
}

/* Course container styling */
.course {
    padding: 10px;
	border: 3px solid #000000;
    border-radius: 5px;
    background-color: #cfcaca;
    margin-top: 10px;
}

/* Course title styling */
.course h2,
.course h3 {
    margin: 0;
    color: #000000;
    font-weight: bold;
    text-align: center;
}

.label {
	font-size: 1em;
}
  
.label strong {
	font-weight: bold; /* Makes the label bold */
}
  

/* Course description styling */
.description {
    color: #000000;
    margin: 10px 0;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 10px;
}

/* Main container for all cards */
.cards-container {
    overflow-y: auto;
    max-height: 80vh;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px; /* Adds some padding around the container */
}

/* Individual card styling */
.card {
    background: linear-gradient(45deg, #fb5d5d, #af0303, hsl(20, 16%, 96%));
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    margin: 20px;
    width: 100%;
    max-width: 600px; /* Maximum width for larger screens */
}

/* Large screens (desktops) */
@media (min-width: 1024px) {
    .cards-container {
        max-width: 100%; /* Limits width for readability on large screens */
    }
    .card {
        flex: 0 1 45%; /* Adjusts the card width */
        margin: 20px 1%; /* Adds a small horizontal gap */
    }
}

/* Medium screens (tablets) */
@media (min-width: 768px) and (max-width: 1023px) {
    .card {
        flex: 0 1 60%; /* Makes cards take up more space */
        margin: 20px auto;
    }
}

/* Small screens (smartphones) */
@media (max-width: 767px) {
    .card {
        width: 100%; /* Full width for small screens */
        margin: 10px 0;
    }
    .course-header {
        flex-direction: column; /* Stacks name and image vertically */
        align-items: center; /* Centers items */
    }
    .course-image {
        margin: 10px 0 0; /* Adds space above the image */
    }
}

/* Adjustments for the Samsung Galaxy Z Fold 6 internal screen (6.3-inch) */
@media (min-width: 600px) and (max-width: 768px) {
    .cards-container {
        padding: 10px; /* Adds padding around the container */
        max-height: 75vh; /* Adjust max height for smaller screens */
    }

    .card {
        flex: 0 1 48%; /* Reduces the card width slightly */
        margin: 15px; /* Adds smaller margin for tighter screen */
    }

    .course-header {
        flex-direction: column; /* Stack items vertically for better readability */
        align-items: center; /* Center align the content */
        margin-bottom: 10px; /* Reduce space between header and course content */
    }

    .course-name {
        font-size: 1.2em; /* Reduce font size for better readability */
        text-align: center; /* Center-align name on smaller screens */
    }

    .course-image {
        width: 120px; /* Slightly reduce the size of the image */
        height: 120px;
        margin-top: 10px; /* Adds space above image */
    }

    .description {
        font-size: 0.9em; /* Reduce font size for better fit */
    }
}


@media (min-width: 500px) and (max-width: 1000px) {

    .cards-container {
        padding: 10px; /* Adds padding around the container */
        max-height: 65vh; /* Adjust max height for smaller screens */
    }
}