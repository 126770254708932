h1, p, ul, li, img {
    margin: 0;
    padding: 0;
    font-family: monospace;
}

body {
    background: linear-gradient(45deg, hsl(0, 50%, 50%), #e64646, #7f0101);
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow: hidden; 
}

.main-content {
    height: 100vh;
}

.home {
    height: 80vh;
    overflow-y: auto;
    padding: 10px;
    overflow: auto;
}


@media (min-width: 1200px) and (max-width: 1300px) {
    .home {
        height: 70vh;
    }
}

@media (min-width: 414px) and (max-width: 900px) {
    .home {
        height: 65vh;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .home {
        height: 80vh;
    }
}

@media (min-width: 800px) and (max-width: 1200px) {
    .home {
        height: 80vh;
    }
}

@media (min-width: 800px) and (max-width: 1200px) {
    .home {
        height: 80vh;
    }
}

@media (min-width: 700px) and (max-width: 1100px) {
    .home {
        height: 75vh;
    }
}


@media (min-width: 375px) and (max-height: 850px) {
    .home {
        height: 70vh;
    }
}






