/* CookiePopup.css */

/* Popup Styling */
.cookie-popup {
    position: fixed;
    top: 50%;  /* Position from the top of the screen */
    left: 50%; /* Position from the left of the screen */
    transform: translate(-50%, -50%); /* Offset to perfectly center it */
    background-color: #000000;
    color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(236, 236, 236, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%; /* Optional: set a width for the popup */
    max-width: 400px; /* Optional: set a max width for larger screens */
  }
  
  .cookie-popup-content {
    text-align: center;
  }
  
  .cookie-popup-button {
    margin-top: 10px;
    background-color: #ff0000;
    color: rgb(255, 255, 255);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cookie-popup-button:hover {
    background-color: #ff0000;
  }
  