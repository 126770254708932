/* Slider.css */
.slider {
	display: flex;
	gap: 15px;  /* Increased gap for better spacing */
	overflow-x: auto;
	padding: 15px;  /* Added padding to give more space around the images */
	width: 100%;  /* Make sure the slider takes the full width of its container */
	box-sizing: border-box;  /* Ensures padding is included in the width */
}

.slider-image {
	width: 100%;  /* Width of the slider images */
	height: 100px; /* Height of the slider images */
	cursor: pointer;
	object-fit: cover;
	object-position: center; /* Center images within their containers */
	border: 2px solid #ddd;
	border-radius: 8px;  /* Optional: rounded corners for the images */
	transition: transform 0.3s ease;
}

.slider-image:hover {
	transform: scale(1.1); /* Slight zoom on hover */
}

/* Modal styles */
.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	max-width: 90%;  /* Increased the max width of the modal */
	max-height: 80%; /* Increased max height of the modal */
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;  /* Prevent image from overflowing the modal */
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
}

.enlarged-image {
	width: 500px;  /* Increased width to 500px */
	height: 500px; /* Increased height to 500px */
	object-fit: cover;  /* Maintain aspect ratio and ensure no distortion */
	object-position: center;  /* Center the image within the container */
	border: 2px solid #ddd;  /* Same border as the slider images */
	border-radius: 8px;  /* Optional: rounded corners for the enlarged image */
}

.close-button {
	margin-top: 20px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	background-color: #ff0000;
	color: white;
	border: none;
	border-radius: 5px;
	transition: background-color 0.3s;
}

.close-button:hover {
	background-color: #e75353; /* Darker blue when hovered */
}


/* Mobile Responsiveness */
@media (max-width: 600px) {
    .slider-image {
        width: 30vw;  /* Each image width is 30% of viewport width */
    }

    .modal {
        max-width: 90vw;
        max-height: 80vh;
    }

    .enlarged-image {
        width: 70vw;
    }

    .close-button {
        font-size: 14px;
    }
}

/* Samsung Galaxy Z Fold 6 (Main Screen in Unfolded Mode) */
@media (min-width: 768px) and (max-width: 1200px) {
    .slider-image {
        width: 25vw;  /* Images will be slightly bigger, taking 25% of the width */
    }

    .modal {
        max-width: 80vw;
        max-height: 80vh;
    }

    .enlarged-image {
        width: 60vw;
    }

    .close-button {
        font-size: 16px;
        padding: 12px 25px;
    }
}

/* Large Screens (MacBook Air, etc.) */
@media (min-width: 1440px) and (max-width: 2560px) {

    .slider-image {
        width: 30vw;  /* Reduce image width on large screens */
		height: 30vh;
    }

    .modal {
        max-width: 70vw;
        max-height: 70vh;
    }

    .enlarged-image {
        width: 30vw;
		height: 50vh;
    }

    .close-button {
        font-size: 20px;
        padding: 15px 30px;
    }
}
